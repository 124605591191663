.navbar {
  overflow: hidden;
}

.navbar .icon {
  display: none;
}

.navbar .hamburger {
  display: none;
  height: 40px;
}

.navbar-content {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: center;
  vertical-align: middle;
  background-color: #116466;
}

.top_bar {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 48px;
  z-index: 9999;
  background-color: #116466;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 700px) {
  .navbar .nav-item:not(.hamburger) {
    display: none;
  }
  .navbar .hamburger {
    display: flex;
    height: 33px;
    font-size: 16px;
  }
  .navbar.responsive .nav-item:not(.hamburger) {
    display: block;
    text-align: left;
    height: 32px;
  }
  .navbar-content.responsive {
    flex-direction: column;
    position: absolute;
    top: 48px;
    height: 190px;
    width: 100%;
    min-width: 250px;
    max-width: 50%;
    text-align: right;
  }
}