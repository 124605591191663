html, body, #root, .App {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Open Sans', "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  margin-top: 10px;
  margin-bottom: 15px;
}

h2 {
  margin-top: 5px;
  margin-bottom: 15px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.primary-color {
  color: #2c3531;
}

.secondary-color {
  color: #116466;
}

.tertiary-color {
  color: #FFCB9A;
}

.primary-bgcolor {
  background-color: #2c3531;
  color: #D1E8E2;
}

.secondary-bgcolor {
  background-color: #116466;
  color: #D1E8E2;
}

.tertiary-bgcolor {
  background-color: #FFCB9A;
  color: #2c3531;
}

a {
  color: #116466;
  text-decoration: none;
  font-weight: bold;
}

a:hover, a:active, a:visited {
  color: #2c3531;
}

input {
  border-radius: 0;
}