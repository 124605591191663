.search_bar {
  position: fixed;
  left: 0;
  top: 0;
  max-width: 300px;
  width: 300px;
  z-index: 9999;
  background-color: transparent;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border: none;
}

.search_bar input {
  border: 0;
  width: 100%;
  height: 18px;
  padding: 5px;
  margin-right: 0;
  outline: none;
  font-size: 14px;
}

.search_bar button {
  height: 28px !important;
  margin-left: 0 !important;
}

.search_bar__results {
  background-color: #116466;
  position: absolute;
  left: 10px;
  top: 48px;
  max-height: 196px;
  width: 100%;
  min-width: 247px;
  max-width: 50%;
  overflow: hidden;
}

.search_bar__result-item {
  display: block;
  text-align: left;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
}

.search_bar__result-item:hover {
  cursor: pointer;
  background-color: #2c3531;
  color: white;
}