.App {}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.mapboxgl-popup-content {
  padding: 0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.mapboxgl-popup-content p {
  font-weight: bold;
}

.button {
  text-transform: uppercase;
  border: 0;
  padding: 10px 10px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 44px;
}

.button:disabled {
  opacity: 0.5;
}

button:active {
  outline: none;
}

.button-large {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  max-height: 50px;
}

.button-small {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  max-height: 34px;
}

.button-primary {
  background-color: #282c34;
  color: white;
}

.button-error {
  background-color: #ff0033;
  color: white;
}

ul {
  padding-left: 15px;
}

.landing-page__item {
  padding: 20px;
  text-align: center;
}

.landing-page {
  font-family: 'Arvo', serif;
  background-color: #2c3531;
}

.nav-item {
  padding-right: 20px;
  padding-left: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  padding-top: 15px;
}

.nav-item>a {
  color: white !important;
}

.nav-item:hover {
  background-color: #282c34 !important;
  cursor: pointer;
}

.nav-item:hover>a {
  color: #D1E8E2 !important;
}

.form {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: none;
  flex: 1;
}

.form input, .form textarea {
  border: 1px solid #116466;
  padding: 10px;
  outline: none;
  font-size: 14px;
  margin-bottom: 10px;
  max-width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  flex: 1;
}

.form label {
  font-size: 13px;
  font-weight: bold;
  color: #116466;
  text-transform: uppercase;
  margin-left: 5px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table>th {
  color: 'white';
}

.table td, .table th {
  padding: 8px;
}

.table tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.table tr:nth-child(even) {
  background-color: white
}

.table tr:hover {
  background-color: #D1E8E2;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #116466;
  color: white;
}

.table button {
  cursor: pointer;
}

.responsive-container {
  overflow: inherit;
  flex: 0 1 auto;
}

@media screen and (max-width:768px) {
  .responsive-container {
    overflow: scroll;
    max-width: calc(100% - 40px);
    border: 1px solid #116466;
    background-color: #116466;
  }
  .table {
    min-width: 768px;
  }
}

.error {
  color: #ff0033;
}

.error-bg {
  background-color: #ff0033;
  color: white;
  font-weight: bold;
}

.success-bg {
  background-color: #4BB543;
  color: white;
}

.toast {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  left: calc(50% - 25%);
  width: 50%;
  padding: 5;
  padding-left: 10;
  padding-Right: 10;
  height: 50px;
}

.toast>p {
  margin-left: 15px;
  margin-right: 15px;
}

.toast-hide {
  height: 0;
  overflow: hidden;
  transition: height 1s ease-out;
}

.toast-show {
  height: 50px;
  transition: height 0.4s ease-in;
}

.sign_in__container {
  background-color: #116466;
  display: flex;
  text-align: center;
  flex: 1;
  max-width: 500px;
  min-width: 300px;
  flex-direction: column;
  padding: 50px;
  margin: auto;
  margin-top: 50px;
  border-radius: 15px;
  padding-top: 15px;
}

.sign_in__container>h1:not(.logo) {
  font-size: 30px;
}

.sign_in__container .logo {
  font-size: 80px;
  flex: 0;
}

@media only screen and (max-width: 600px) {
  .sign_in__container {
    max-width: 400px;
    min-width: 250px;
    padding: 10px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0;
  }
  .sign_in__container>h1:not(.logo) {
    font-size: 20px;
  }
  .sign_in__container .logo {
    font-size: 60px;
  }
}

.modal {
  position: fixed;
  z-index: 9999;
  left: 100px;
  top: 10%;
  width: 50%;
  padding: 5px;
  padding-left: 10;
  padding-Right: 10;
  max-height: 50%;
  height: 50%;
  background-color: red;
  visibility: hidden;
  border-radius: 20px;
  left: calc(50% - 25%);
  box-shadow: 2px 4px black;
  border: 1px solid black;
}

.modal-backdrop {
  width: 100vw;
  height: 100vh;
  background-color: gray;
  opacity: 0.8;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.modal-content {
  overflow: auto;
  max-height: 100%;
}

.modal-hide {
  transition: visibility 1s ease-out;
  visibility: hidden;
  overflow: hidden;
  height: 0;
}

.modal-show {
  visibility: visible;
  transition: visibility 0.4s ease-in;
}

.modal-container {
  position: relative;
}

.map__context-menu {
  position: fixed;
  z-index: 9999;
  max-width: 200px;
  min-height: 50px;
  background-color: white;
  padding: 10px;
}

.map__context-menu>button {
  margin-left: 0;
  float: right;
}

.map__context-menu__content>a {
  color: #116466 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.map__context-menu__content {
  min-height: 30px;
}